// ** React Imports
import {Suspense, lazy} from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import {Provider} from 'react-redux'
import {store} from './redux/storeConfig/store'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import {ToastContainer} from 'react-toastify'
import {AbilityContext} from './utility/context/Can'
import {ThemeContext} from './utility/context/ThemeColors'
import {IntlProviderWrapper} from './utility/context/Internationalization'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './assets/custom-assets/dataTables.bootstrap5.min.css'
import './assets/custom-assets/components.min.css'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './assets/custom-assets/base-main.css'
import './assets/custom-assets/colors.min.css'
import './assets/custom-assets/authentication.css'
import './assets/custom-assets/bordered-layout.min.css'
import './assets/custom-assets/buttons.bootstrap5.min.css'
import './assets/custom-assets/chart-apex.min.css'
import './assets/custom-assets/dark-layout.css'
import './assets/custom-assets/dark-layout.min.css'
import './assets/custom-assets/dashboard-ecommerce.min.css'
import './assets/custom-assets/flatpickr.min.css'


import * as serviceWorker from './serviceWorker'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

import i18n from "i18next"
import {initReactI18next} from "react-i18next"

import {ar} from "./lang/ar"
import {en} from "./lang/en"
const { REACT_APP_BASEURL } = process.env

import axios from "axios"
axios.defaults.baseURL = REACT_APP_BASEURL
// start arabic fonts
import '@assets/fonts/arabic/Almarai/Almarai-Regular.ttf'
import '@assets/fonts/index.css'
// end arabic fonts

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en: {
                translation: {
                   ...en
                }
            },
            ar: {
                translation: {
                    ...ar
                }
            }
        },
        lng: localStorage.getItem('lang_code') || 'en', // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        interpolation: {
            escapeValue: true // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    }).then(() => console.log("i18next initialized"))
    .catch(error => console.error("i18next initialization failed:", error));

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<Spinner/>}>
            <AbilityContext.Provider value={ability}>
                <ThemeContext>
                    <IntlProviderWrapper>
                        <LazyApp/>
                        <ToastContainer newestOnTop/>
                    </IntlProviderWrapper>
                </ThemeContext>
            </AbilityContext.Provider>
        </Suspense>
    </Provider>,
    document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

