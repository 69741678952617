const initialState = {
    contactPushList:[],
    mobilePushList:[],
    groupPushList:[]
}

const pushReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_PUSH_CONTACT_LIST':
            initialState.contactPushList = action.payload
            return {
                ...state,
                contactPushList: action.payload
            };
        case 'SET_PUSH_MOBILE_LIST':
            initialState.mobilePushList = action.payload
            return {
                ...state,
                mobilePushList: action.payload
            };
        case 'SET_PUSH_GROUP_LIST':
            initialState.groupPushList = action.payload
            return {
                ...state,
                groupPushList: action.payload
            };
        default:
            return initialState
    }
}

export default pushReducer