const en = {
    "Linq2 Resources" : "Linq2 Resources",
    "Template":"Template",
    "Templatesasas":"Templatesasas",
    "Hello" : "Hello",
    "Add Contact" : "Add Contact",
    "Contact" : "Contact",
    "Import File" : "Import File",
    "Export File" : "Export File",
    "Contact List Management" : "Contact List Management",
    "Group List" : "Group List",
    "Messages" : "Messages",
    "Arabic" : "Arabic",
    "English" : "English",
    "Reports" : "Reports",
    "Add Group" : "Add Group",

    // attributes
    "Job Name" : "Job Name",
    "To Contacts" : "To Contacts",
    "Sender ID" : "Sender ID",
    "Message" : "Message",
    "Select template" : "Select template",
    "Show Advanced Options" : "Show Advanced Options",
    "To Groups" : "To Groups",
    "Options" : "Options",
    "Priority" : "Priority",
    "Provider" : "Provider",
    "Credits" : "Credits",
    "From" : "From",
    "Subject" : "Subject",
    "To Emails" : "To Emails",
    "Title" : "Title",
    "Push Notification Provider" : "Push Notification Provider",
    "Message File" : "Message File",
    "Source Directory" : "Source Directory",
    "Sender" : "Sender",
    "Start ScheduleDate & TIme" : "Start ScheduleDate & TIme",
    "Save to Draft" : "Save to Draft",
    "Send Test SMS" : "Send Test SMS",
    "Send" : "Send",
    "Characters" : "Characters",
    "Select Groups" : "Select Groups",
    "Select Emails" : "Select Emails",
    "Test Email" : "Test Email",
    "Toggle Dropdown" : "Toggle Dropdown",
    "Schedule Send" : "Schedule Send",
    "Select Contacts" : "Select Contacts",

    // taps
    "Compose" : "Compose",
    "Draft" : "Draft",
    "Schedules" : "Schedules",
    "History" : "History",
    "Templates" : "Templates",
    "Upload" : "Upload",
    "Close" : "Close",
    "Save" : "Save",
    "Set" : "Set",
    "Remove" : "Remove",

    // Messages
    "Field is required" : "Field is required",
    "Schedule Send Plan" : "Schedule Send Plan",
    "branches":"branches",
    "Today SMS":"Today SMS",
    "Total SMS":"Total SMS",
    "errorLogs":"error Logs",
    "Dashboard":"Dashboard"
  
    
}

export {en}
