const initialState = {
    contactList:[],
    emailList:[],
    groupList:[]
}

const emailReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_CONTACT_LIST':
            initialState.contactList = action.payload
            return {
                ...state,
                contactList: action.payload
            };
        case 'SET_EMAIL_LIST':
            initialState.emailList = action.payload
            return {
                ...state,
                emailList: action.payload
            };
        case 'SET_GROUP_LIST':
            initialState.groupList = action.payload
            return {
                ...state,
                groupList: action.payload
            };
        default:
            return initialState
    }
}

export default emailReducer