// ** Initial State
const initialState = {
  suggestions: [],
  bookmarks: [],
  query: '',
  navbarList: {}
  
}

const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_NAVBAR':
      return { ...state, navbarList: action.payload }
    default:
      return state
  }
}

export default navbarReducer
