const initialState = {
    mobile : [],
    emailContact : [],
    groupsList : [],
    draft : [],
    activeSmsDraft : {},
    activeEmailDraft:{},
    SmsHistoryParams : {},
    ContactListObject:[],
    emailContact_cc:[],
    emailContact_bcc:[],
    pushContact:[],
    activePushDraft:{},
    PushSchedules:[],
    PushSchedulesObject:[]
}

const smsSelectReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SMS_SELECT_CONTACTS_LIST':
            initialState.mobile = [...initialState.mobile, ...action.payload] 
            return initialState

        case 'EMAIL_SELECT_CONTACTS_LIST':
                initialState.emailContact = action.payload
                return initialState
        case 'EMAIL_SELECT_CONTACTS_LIST_CC':
            initialState.emailContact_cc = action.payload
            return initialState
        case 'EMAIL_ADD_CONTACTS_LIST_BCC':
            initialState.emailContact_bcc = action.payload
            return initialState
        case 'REMOVE_CONTACTS_LIST_CC':
            initialState.emailContact_cc = [] 
            return initialState
        case 'REMOVE_CONTACTS_LIST_BCC':
            initialState.emailContact_bcc = [] 
            return initialState
        case 'SMS_ADD_CONTACTS_LIST':
            initialState.mobile = action.payload
            return initialState
        case 'EMAIL_ADD_CONTACTS_LIST':
                initialState.emailContact = action.payload
                return initialState
        case 'SMS_SELECT_GROUP_LIST':
            initialState.groupsList = action.payload
            return initialState
        case 'SMS_GET_LIST_DRAFT':
            initialState.draft = action.payload
            return initialState
        case 'SMS_ADD_DRAFT_LIST':
            initialState.draft = [...initialState.draft, action.payload]
            return initialState

        case 'SMS_ACTIVE_DRAFT':
            initialState.activeSmsDraft = action.payload
            
            return {
                ...state,
                activeEmailDraft: action.payload
            };
        case 'EMAIL_ACTIVE_DRAFT':
            return {
                ...state,
                activeEmailDraft: action.payload
            };

        case 'SMS_HISTORY_PARAMS':
            return {
                ...state,
                SmsHistoryParams: action.payload
            };
        case 'OBJECT_CONTACTS_LIST':
            return {
                ...state,
                ContactListObject: action.payload
            };
        case 'PUSH_SELECT_CONTACTS_LIST':
            return {
                ...state,
                pushContact: action.payload
            };

        case 'PUSH_SET_CONTACTS_LIST':
            return {
                ...state,
                pushContact: action.payload
            };
        case 'PUSH_ACTIVE_DRAFT':
            return {
                ...state,
                activePushDraft: action.payload
            };
        case 'PUSH_SCHEDULES_CONTACT':
            return {
                ...state,
                PushSchedules: action.payload
            };
        case 'PUSH_SCHEDULES_CONTACT_OBJECT': 
            return {
                ...state,
                PushSchedulesObject: action.payload
            };
        default:
            return initialState
    }
}

export default smsSelectReducer