const ar = {
    "Add Contact" : "إضافة جهة اتصال",
    "Contact" : "اتصال",
    "Import File" : "استيراد ملف",
    "Export File" : "تصدير ملف",
    "Contact List Management" : "إدارة قائمة جهات الاتصال",
    "Group List" : "قائمة المجموعات",
    "Arabic" : "عربي",
    "English" : "انجليزي",
    "Reports" : "التقارير",
    "Add Group" : "اضف مجموعة",
    "Statistics" : "الاحصائيات",
    "Push" : "إعلام التنبيه",
    "sent": "مرسل",
    "rejected": "مرفوض",
    "received": "مستلم",
    "delivered": "واصل",
    "pending": "معلق",
    "expired": "منتهي",
    "User Help" : "المساعدة للمستخدم",
    "Desk." : "المكتب",
    "How to send messages?" : "كيف ترسل الرسائل النصية؟",
    "How to manage contacts list?" : "كيف تحكم في قائمة المستخدمين؟",
    "How do I get reports?": "كيف أحصل على التقارير؟",
    "Help Center" : "مركز المساعدة",
    "left": "right",
    "Job Name" : "اسم الوظيفة",
    "To Contacts" : "إلى جهات الاتصال",
    "Sender ID" : "هوية المرسل",
    "Message" : "الرسالة",
    "Select template" : "حدد قالب",
    "Show Advanced Options" : "عرض الخيارات المتقدمة",
    "To Groups" : "إلى المجموعات",
    "Options" : "الخيرات",
    "Priority" : "الأولوية",
    "Credits" : "الاعتمادات",
    "To Emails" : "إلى رسائل البريد الإلكتروني",
    "Title" : "العنوان",
    "Push Notification Provider" : "مزود الإشعارات",
    "Message File" : "ملف الرسائل",
    "Source Directory" : "دليل المصدر",
    "Sender" : "المرسل",
    "Start Schedule Date & TIme" : "تاريخ البدء والجدول الزمني",
    "Save to Draft" : "حفظ في المسودة",
    "Send Test SMS" : "إرسال اختبار SMS",
    "Send" : "ارسال",
    "Characters" : "الاحرف",
    "Select Groups" : "حدد المجموعات ",
    "Select Emails" : "حدد الاميلات",
    "Test Email" : "اختبار الاميل",
    "Toggle Dropdown" : "تبديل القائمة المنسدلة",
    "Schedule Send" : "جدولة الإرسال",
    "Test Now" : "اختبر الان",
    "Select Contacts" : "حدد جهات الاتصال",
    "Compose" : "قسم المراسلة",
    "Draft" : "مسودة",
    "Schedules" : "الرسائل المجدولة",
    "History" : "المراجع السابقة",
    "Templates" : "القوالب",
    "Upload" : "رفع الملفات",
    "Close" : "اغلاق",
    "Set" : "اضافة",
    "Remove" : "حذف",
  
    "Field is required" : "الحقل مطلوب",
    "Schedule Send Plan" : "خطة الارسال المتزامن",
    "branches": "الفروع",
    "Templatesasas":"سشنتشست",
    "Template":"القوالب",
    "Today SMS":"رسائل اليوم",
    "Total SMS":"العدد الكلي",
    "errorLogs":"الاخطاء",
    "All Channels Performance" : "أداء جميع القنوات",
    "All Channels" : "جميع القنوات",
    "SMS" : "الرسائل النصية",
    "Email" : "البريد الإلكتروني",
    "Push Notification" : "الإشعار الفوري",
    "Sent Messages" : "الرسائل المرسلة",
    "Breakdown by Service Status" : "مقسم حسب حالة الخدمة",
    "Breakdown by Status City" : "مقسم حسب المدينة",
    "Delivered" : "تم التسليم",
    "Transactions" : "المعاملات",
    "Efficiency" : "الكفاءة",
    "Delivery Rate" : "معدل التسليم",
    "Email Template" : "نموذج البريد الإلكتروني",
    "Push Notification Template" : "نموذج الإشعار الفوري",
    "SMS Template" : "نموذج الرسائل النصية",
    "Add Email Template" : "إضافة نموذج البريد الإلكتروني",
    "Name" : "الاسم",
    "Description" : "الوصف",
    "Subject" : "الموضوع",
    "Type" : "النوع",
    "Messages" : "الرسائل",
    "Save" : "حفظ",
    "Cancel" : "إلغاء",
    "Edit Email Template" : "تحرير نموذج البريد الإلكتروني",
    "Email Template Details" : "تفاصيل نموذج البريد الإلكتروني",
    "SMS Template Details" : "تفاصيل نموذج الرسائل النصية",
    "Push Notification Details" : "تفاصيل الإشعار الفوري",
    "Template Name" : "اسم النموذج",
    "Template Description" : "وصف النموذج",
    "Template Type" : "نوع النموذج",
    "Template Header" : "رأس النموذج",
    "Email Message" : "رسالة البريد الإلكتروني",
    "High Priority" : "أولوية عالية",
    "Address Book" : "دليل العناوين",
    "To" : "إلى",
    "Provider" : "مزود",
    "Select Template" : "حدد النموذج",
    "From" : "من",
    "TO" : "إلى",
    "Language" : "اللغة",
    "language" : "اللغة",
    "Mobile" : "جوال",
    "Status" : "الحالة",
    "status" : "الحالة",
    "Records Per Page" : "سجلات لكل صفحة",
    "Message Body" : "نص الرسالة",
    "Export" : "تصدير",
    "CSV" : "CSV",
    "View Sent Messages" : "عرض الرسائل المرسلة",
    "CC" : "CC",
    "Breakdown by provider": "التقسيم حسب المزود",
    "Pending":"قيد الانتظار",
    "Add Template":"اضافة تيمبليت",
    "Messaging":"قسم الرسائل",
    "Enter Phone Number": "أدخل رقم الهاتف",
    "Please enter SMS message body":"الرجاء إدخال نص الرسالة النصية",
    "Save to Schedule":"جدول الارسال",
    "Message body":"نص الرسالة",
    "Dashboard":"داشبورد",
    "Campaign":"الحملة",
    "Notification History":"سجل التنبيهات",
    "Enter Subject":"أدخل الموضوع",
    "Enter Emails":"أدخل البريد الإلكتروني",
    "Save Schedule":"جدول الرسالة",
    "Save Draft":"حفظ كمسودة",
    "Your App Name":"اسم التطبيق",
    "Attached Image":"صورة مرفقة",
    "Send Schedule":"جدول الرسالة",
    "Enter Contacts":"أدخل جهات الاتصال",
    "New Group":"مجموعة جديدة",
    "Group Name":"أسم المجموعة",
    "Priority":"أولوية",
    "Select":"اختر المستخدمين",
    "Group 1":"مجموعة 1",
    "Edit Group":"تحرير المجموعة",
    "Group Members":"أعضاء المجموعة",
    "Delete":"حذف",
    "Create Test":"إنشاء اختبار",
    "Test Name":"اسم الاختبار",
    "Group Provider":"مزود المجموعة",
    "Test Unit":"وحدة الاختبار",
    "Do Test":"قم بالاختبار",
    "Test Method":"طريقة اختبار",
    "Less Than duration":"أقل من المدة",
    "Event Log":"سجل الأحداث",
    "Error Log":"سجل الأخطاء",
    "Module Settings":"إعدادات الوحدة",
    "Display":"عرض",
    "Active":"نشط",
    "In-Active":"غير نشط",
    "All":"الكل",
    "Configuration":"إعدادات",
    "Add Value":"إضافة قيمة",
    "Prop Name":"الاسم",
    "Prop Value":"القيمة"
}

export {ar}