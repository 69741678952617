const initialState = {
    contactList:[],
    mobileList:[],
    groupList:[]
}

const smsReducer = (state = initialState, action) => {
    switch (action.type){
        case 'SET_CONTACT_LIST':
            initialState.contactList = action.payload
            return {
                ...state,
                contactList: action.payload
            };
        case 'SET_MOBILE_LIST':
            initialState.mobileList = action.payload
            return {
                ...state,
                mobileList: action.payload
            };
        case 'SET_GROUP_LIST':
            initialState.groupList = action.payload
            return {
                ...state,
                groupList: action.payload
            };
        default:
            return initialState
    }
}

export default smsReducer