// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import smsSelectReducer from "./messaging";
import smsReducer from "./sms";
import pushReducer from "./push";
import emailReducer from "./email";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  smsSelectReducer,
  smsReducer,
  pushReducer,
  emailReducer
})

export default rootReducer
