// ** React Imports
import {useState, createContext} from 'react'

// ** Intl Provider Import
import {IntlProvider} from 'react-intl'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesAr from '@assets/data/locales/ar.json'

// ** User Language Data
import userMessagesEn from '@src/assets/data/locales/en.json'
import userMessagesAr from '@src/assets/data/locales/ar.json'

// ** Menu msg obj
const menuMessages = {
    en: {...messagesEn, ...userMessagesEn},
    ar: {...messagesAr, ...userMessagesAr}
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({children}) => {
    // ** States
    const [locale, setLocale] = useState(localStorage.getItem('lang_code') || 'en')
    const [messages, setMessages] = useState(menuMessages[localStorage.getItem('lang_code') || 'en'])

    // ** Switches Language
    const switchLanguage = lang => {
        localStorage.setItem('lang_code', lang)
        setLocale(lang)
        setMessages(menuMessages[lang])

        switch (lang) {
            case 'ar':
                localStorage.setItem('is_rtl', true)
                break
            case 'en':
                localStorage.setItem('is_rtl', false)
                break
            default:
                break
        }

        location.reload()
    }

    return (
        <Context.Provider value={{locale, switchLanguage}}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export {IntlProviderWrapper, Context as IntlContext}
